import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
	providedIn: "root",
})
export class ToasterMessagesService {
	constructor(
		private messageService: MessageService,
		private TranslateService: TranslateService
	) {}

	setLanguageDirection(dir: string) {
		document.documentElement.dir = dir;
	}

	showSuccess(
		message: string,
		stickyOption: boolean = false,
		duration: number = 3000
	) {
		const position = this.getPositionBasedOnLanguage();
		this.messageService.add({
			severity: "success",
			detail: message,
			sticky: stickyOption,
			// key:position,
			life: duration,
		});
	}

	showError(
		message: string,
		stickyOption: boolean = false,
		duration: number = 3000
	) {
		const position = this.getPositionBasedOnLanguage();
		this.messageService.add({
			severity: "error",
			detail: message,
			sticky: stickyOption,
			// key:position,
			life: duration,
		});
	}

	showWarning(
		message: string,
		stickyOption: boolean = false,
		duration: number = 3000
	) {
		const position = this.getPositionBasedOnLanguage();
		this.messageService.add({
			severity: "warn",
			detail: message,
			sticky: stickyOption,
			// key:position,
			life: duration,
		});
	}

	showInfo(
		message: string,
		stickyOption: boolean = false,
		duration: number = 3000
	) {
		const position = this.getPositionBasedOnLanguage();
		this.messageService.add({
			severity: "info",
			detail: message,
			sticky: stickyOption,
			// key:position,
			life: duration,
		});
	}

	clearToasters() {
		this.messageService.clear();
	}

	private getPositionBasedOnLanguage(): string {
		const languageDirection = document.documentElement.dir;
		return languageDirection === "rtl" ? "bottomright" : "bottomleft";
	}
}
